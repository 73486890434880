import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styles from './exitbutton.module.css'


export default function ExitButton(props) {
    const color = props.color;
    const exit = props.onExit;

    return(
        <IconButton className={styles.exitbutton} aria-label="exit" size="large" sx={{ m: 4, backgroundColor: color}} onClick={exit}>
            <CloseIcon fontSize="inherit" />
        </IconButton>
    )
}